export const STOREFRONT_API_URL = process.env.NEXT_PUBLIC_STOREFRONT_API_URL as string;
export const SEARCH_API_URL = process.env.NEXT_PUBLIC_SEARCH_API_URL as string;
export const GA_CONTAINER_ID = process.env.NEXT_PUBLIC_GA_CONTAINER_ID as string;

export const STORE_CODE_LOCALE = {
  bodylab_nl: 'nl_NL',
  gymqueen_nl: 'nl_NL',
  vomachterhof: 'de_DE'
};

export const MEDIA_CLOUDINARY_PARAMETERS = 't_dt';
// 'b_white,f_auto,q_auto,fl_force_strip.preserve_transparency.progressive,f_webp';
export const MEDIA_URL = 'https://cdn11.vitafy.de';

export const USE_NEXT_LINKS = true;
export const CAPTCHA_PUBLIC_KEY = {
  vomachterhof: process.env.NEXT_PUBLIC_CAPTCHA_PUBLIC_KEY || '6LcWEh4eAAAAAHD8XSQKvJ1ajSWn8rjqnni_Vl-G'
};

// assets served from next.js
export const ASSET_URL =
  typeof process.env.NEXT_PUBLIC_ASSET_URL === 'undefined'
    ? 'https://sf.vitafy.de'
    : (process.env.NEXT_PUBLIC_ASSET_URL as string);

export const FAVICONS = {
  bodylab_nl: 'https://bodylab24.ch/media/favicon/stores/29/bodylab.png',
  bodylab_ch: 'https://bodylab24.ch/media/favicon/stores/29/bodylab.png',
  bodylab: 'https://bodylab24.ch/media/favicon/stores/29/bodylab.png',
  gymqueen_nl: '',
  vomachterhof: `${MEDIA_URL}/vitafy/image/upload/v1687265390/vomachterhof-favicon.ico`,
  vomachterhof_ch: `${MEDIA_URL}/vitafy/image/upload/v1687265390/vomachterhof-favicon.ico`,
  de_de: 'https://cdn2.vitafy.de/media/favicon/default/favicon.png'
};

export const FORMAT_CURRENCY_OPTIONS = {
  vomachterhof: {
    currency: 'EUR',
    locale: 'de-DE',
    currencySymbol: '€'
  },
  vomachterhof_ch: {
    currency: 'CHF',
    locale: 'de-CH',
    currencySymbol: 'CHF'
  },
  bodylab_ch: {
    currency: 'CHF',
    locale: 'de-CH',
    currencySymbol: 'CHF'
  },
  bodylab: {
    currency: 'EUR',
    locale: 'de-DE',
    currencySymbol: '€'
  },
  de_de: {
    currency: 'EUR',
    locale: 'de-DE',
    currencySymbol: '€'
  }
};
